import React, { useEffect, useState } from 'react';
import useMetaMask from "../../wallet/hook";

import { HalfMalf } from "react-spinner-animated";
import "react-spinner-animated/dist/index.css";

import axios from "axios";
import { toast } from "react-toastify";
import config from "../../confing.json";

export default function UsdcTx() {

    const {
        isActive,
        account
    } = useMetaMask();

    const [loading, setLoading] = useState(false);
    const [transactionList, setTransactionList] = useState([]);
    var API_URL = process.env.REACT_APP_API_URL;

    const notify = (isError, msg) => {
        if (isError) {
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.success(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getTransaction = async () => {

        await axios
            .post(`${API_URL}getUsdcTransactions`, {
                address: account,
            })
            .then(function (response) {
                if (response.status) {
                    setTransactionList(response.data.data);
                }
            });
    }

    const reSwap = async (transaction) => {

        setLoading(true);
        await axios
            .post(`${API_URL}retryUsdcSwap`, {
                fromChain: transaction.fromChain,
                toChain: transaction.toChain,
                hash: transaction.fromTransactionHash,
                account: transaction.address
            })
            .then(function (response) {
                if (response.status === false) {
                    notify(true, response.error);
                } else {
                    notify(
                        false,
                        `Transaction successful, Please check your wallet.`
                    );
                }
            })
            .catch(function (err) {
                notify(true, err.message);
            });

        await getTransaction();
        setLoading(false);
    }

    useEffect(() => {
        if (isActive) {
            getTransaction();
        }
    }, [isActive]);

    return (
        <div>
            <section className='transiction-table-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xl-12'>
                            <div className='transation-title'>Transactions History</div>
                            <div className='table-responsive scroll'>
                            <div className="start-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col"> Network</th>
                                                <th scope="col"> From TXNHash</th>
                                                <th scope="col"> To TXNHash</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactionList.length === 0 ?
                                                <tr>
                                                     <td colSpan={6} className="table-img">
                                                        <img src="/images/not_found.png"></img>
                                                    </td>
                                                </tr>
                                                :
                                                transactionList.map((e, index) => <tr key={index}>

                                                    <td>{e.date}</td>
                                                    <td>
                                                        <div className="country-icon">
                                                            <img src={e.fromChain === 'BSC' ? config.BNB.BNB.logo : e.fromChain === "ETH" ? config.ETH.ETH.logo : config.BRISE.BRISE.logo} alt="" /><i className="fa fa-arrow-right" aria-hidden="true"></i>
                                                            <img src={e.toChain === 'BSC' ? config.BNB.BNB.logo : e.toChain === "ETH" ? config.ETH.ETH.logo : config.BRISE.BRISE.logo} alt="" />
                                                        </div>
                                                    </td>
                                                    <td><a
                                                        href={
                                                            e.fromChain === 'BSC' ?
                                                                `https://bscscan.com/tx/${e.fromTransactionHash}`
                                                                : e.fromChain === 'ETH' ?
                                                                    `https://etherscan.io/tx/${e.fromTransactionHash}` :
                                                                    `https://brisescan.com/tx/${e.fromTransactionHash}`
                                                        }
                                                        target={"_blank"}
                                                    >
                                                        {e?.fromTransactionHash?.slice(0, 6)
                                                            .concat(`...${e?.fromTransactionHash?.slice(-4)}`)}
                                                    </a></td>
                                                    <td><a
                                                        href={
                                                            e.toChain === 'BSC' ?
                                                                `https://bscscan.com/tx/${e.toTransactionHash}`
                                                                : e.toChain === 'ETH' ?
                                                                    `https://etherscan.io/tx/${e.toTransactionHash}` :
                                                                    `https://brisescan.com/tx/${e.toTransactionHash}`
                                                        }
                                                        target={"_blank"}
                                                    >
                                                        {e?.toTransactionHash?.slice(0, 6)
                                                            .concat(`...${e?.toTransactionHash?.slice(-4)}`)}
                                                    </a></td>
                                                    <td>{e.amount}</td>
                                                    <td>
                                                        {e.isDone ? <button type="button" className="show-rpt">Success</button> : <button type='button' disabled={loading} onClick={() => reSwap(e)} className='show-rpt'>Retry</button>}
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className={`modal ${loading ? "show" : ""}`}
                id="myModal"
                style={{
                    display: `${loading ? "block" : "none"}`,
                }}
            >
                <HalfMalf
                    text={
                        "Do not close or refresh this window while processing the transaction."
                    }
                    bgColor={"#ffff"}
                    width={"250px"}
                    height={"250px"}
                    center={true}
                />
            </div>
        </div>
    )
}
