import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [1, 56, 32520],
});

export const walletConnect = new WalletConnectConnector({
  rpc: {
    1: "https://mainnet.infura.io/v3/5477a53031bf4b8c8970e7dc928acd10",
    56: "https://bsc-dataseed1.binance.org/",
    32520: "https://mainnet-rpc.brisescan.com/"
  },
  bridge: "https://bridge.walletconnect.org",
  infuraId: "5477a53031bf4b8c8970e7dc928acd10",
  qrcode: true,
  pollingInterval: 12000,
});


// rpc: {
//   1: "https://mainnet.infura.io/v3/5477a53031bf4b8c8970e7dc928acd10",
//   56: "https://bsc-dataseed1.binance.org/",
//   32520: "https://mainnet-rpc.brisescan.com/"
// },