import { Routes, Route } from "react-router-dom";
// import Navbar from "./component/Navbar";
import Layout from "./component/layout";
import Home from "./Pages/brise/brise";
import BitgertTx from "./Pages/brise/briseTx";

import UsdtTx from "./Pages/usdt/usdtTx";
import Usdt from "./Pages/usdt/Usdt";

import UsdcTx from "./Pages/usdc/usdcTx";
import Usdc from "./Pages/usdc/Usdc";

import BUSD from "./Pages/busd";
import BUSDTx from "./Pages/busd/tx";

import BNB from "./Pages/bnb";
import BNBTx from "./Pages/bnb/tx";

import ETH from "./Pages/eth";
import ETHTx from "./Pages/eth/tx";

import MATIC from "./Pages/matic";
import MATICTx from "./Pages/matic/tx";

import SHIB from "./Pages/shib";
import SHIBTx from "./Pages/shib/tx";


function App() {
  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="bitgertTx" element={<BitgertTx />} />

        <Route path="busd" element={<BUSD />} />
        <Route path="busdTx" element={<BUSDTx />} />

        <Route path="usdt" element={<Usdt />} />
        <Route path="usdtTx" element={<UsdtTx />} />

        <Route path="usdt" element={<Usdt />} />
        <Route path="usdtTx" element={<UsdtTx />} />

        <Route path="usdc" element={<Usdc />} />
        <Route path="usdcTx" element={<UsdcTx />} />

        <Route path="bnb" element={<BNB />} />
        <Route path="bnbTx" element={<BNBTx />} />

        <Route path="eth" element={<ETH />} />
        <Route path="ethTx" element={<ETHTx />} />

        <Route path="matic" element={<MATIC />} />
        <Route path="maticTx" element={<MATICTx />} />

        <Route path="shib" element={<SHIB />} />
        <Route path="shibTx" element={<SHIBTx />} />
      </Route>
    </Routes>


  );
}

export default App;
